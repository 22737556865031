* {
  overflow: hidden;
}
.fade-in {
  transition: opacity 1.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease;
}

body {
  overflow: hidden;
  background-image: linear-gradient(to top, #2d295e, #202150, #131a41, #081233, #000426);
  height: 101vh;
  width: 101vw;
  display: flex;
  justify-content: center;
  align-items: center;
}